/** @jsx jsx */
import { jsx, Image, Flex} from 'theme-ui';
import ConditionalLayout from "../components/ConditionalLayout"
import SEO from "../components/seo"

import machineEMS from "assets/images/machineEMS.png"
import { Divider } from '@theme-ui/components';

const modalStyle = {
    background: `rgba(0,0,0,0.03)`,
}

const EMSModal = () => (
  <ConditionalLayout style={modalStyle}>
    <SEO title="Page two" />
    <Flex sx={{alignItems: 'end'}}>
        <h1 sx={styles.modalHead}>次世代EMS・機械のご紹介</h1>
        <Divider sx={{marginRight: '51px', display: ['none', 'block']}}/>
    </Flex>
    <h2 sx={styles.modalSubHead}>EMSと次世代EMS「ヘラクレス」との違い</h2>

    <div sx={styles.modalBodyWrap}>
        <div sx={styles.modalBody}>
            <p>
                筋肉表層に作用する深さに差があります EMSは皮下約1cmの筋肉表層に作用しますが次世代EMS ヘラクレスは皮下約7cmまで作用します。
            </p>
            <p>
                筋肉増大 + 脂肪減少 に効果があります また、臀部の形状改善、引き締まったボディラインをサポートします。
            </p>
            <p>
                既存のエステ機器、EMSでは筋肉への影響はありませんでしたが 次世代EMS機はインナーマッスルにアプローチし、筋肉量を 増やしながら脂肪を減らすことができる装置です
            </p>
            <Image src={machineEMS} sx={styles.modalBodyImage}/>
        </div>
    </div>
  </ConditionalLayout>
)

export default EMSModal

const styles = {
    modalHead: {
        mb: ['10px', '16px'],
        mt: ['40px', '75px'],
        pl: ['15px', '51px'],
        backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 25%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 400,
        fontSize: [22, 32],
    },
    modalSubHead: {
        fontSize: [16, 25],
        pl: ['15px', '51px'],
        mb: '10px',
        mt: 0,
    },
    modalBodyWrap: {
        height: ['auto', '400px'],
        background: 'linear-gradient(99.22deg, rgba(217, 237, 255, 0.5) -0.11%, rgba(255, 232, 230, 0.5) 68.8%)',
        fontSize: 14,
        width: '100%',
        // marginLeft: '-50px',
    },
    modalBody: {
        maxWidth: ['auto', '570px'],
        position: 'relative',
        padding: ['15px 0 270px 15px', '41px 0px 128px 51px'],
        // pb: ['270px', 0],
    },
    modalBodyImage: {
        position: ['absolute', 'absolute', 'absolute'],
        width: ['70%', 'unset'],
        right: ['0', '-432px'],
        top: ['unset', '-130px'],
    }
}